import { submitContactFormEndpoint } from './endpoints';
import { newApi, oldApi } from './api.instance';

export class FormsApis {
  public static submitContactForm = async (body: any, language?: string) => {
    const END_POINT = submitContactFormEndpoint(language);
    return oldApi().post(END_POINT, body);
  };

  public static submitContactFormNew = async (body: any) => {
    return newApi().post('/v1/forms', body);
  };
}
