import { FormTypes } from '../../../new-lib/constants/common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useFormik } from 'formik';
import sbjs from 'sourcebuster';
import Cookies from 'universal-cookie';
import { FormsApis } from '../../../new-lib/apis/forms.apis';
import { logEvent } from '../../../new-lib/analytics';

interface FormProps {
  onFormSubmit: () => void;
  formSource?: string;
  location?:
    | string
    | {
        areaId?: string;
        developerId?: string;
        compoundId?: string;
        propertyId?: string;
      };
  landingPageId?: number;
}

export type FormValues = {
  contact_name: string;
  contact_number: string;
  contact_email: string;
  contact_notes: string;
  contact_date: string;
  contact_time: string;
  contact_meeting_location: string;
  contact_address: string;
  compound: {
    label?: string;
    value?: string;
    nawy_organization_id?: number | null;
  };
  seller: boolean;
  property_type: {
    label?: string;
    value?: string;
  };
  finishing_type: {
    label?: string;
    value?: string;
  };
  area: {
    label?: string;
    value?: string;
  };
  working_in_realestate: boolean | null;
  commercial_record: boolean | null;
};

const initialFormValues: FormValues = {
  contact_name: '',
  contact_number: '',
  contact_email: '',
  contact_notes: '',
  contact_date: '',
  contact_time: '',
  contact_meeting_location: '',
  contact_address: '',
  seller: false,
  compound: {},
  property_type: {},
  finishing_type: {},
  area: {},
  working_in_realestate: null,
  commercial_record: null
};

export const useContactForm = ({
  onFormSubmit,
  formSource,
  location,
  landingPageId
}: FormProps) => {
  const { t } = useTranslation('contact');
  const router = useRouter();
  const { asPath, query } = router;
  const [customerNumber, setCustomerNumber] = useState<any>({});

  const [locationId, setLocationId] = useState<any>('');

  const clientId = new Cookies().get('clientID', { doNotParse: true });

  useEffect(() => {
    setLocationId(new Date().getTime().toString());
  }, []);

  const onPhoneNumberChange = (valid: any, value: any) => {
    setCustomerNumber({
      valid,
      value: value.replace(/[٠١٢٣٤٥٦٧٨٩]/g, function (d: any) {})
    });
  };

  const getFormExtraData = (formData: any, values: FormValues) => {
    switch (formSource) {
      case 'scheduleMeeting':
        formData.extra = {
          meeting_date: values?.contact_date,
          meeting_time: values?.contact_time,
          meeting_location: values?.contact_meeting_location,
          method_of_contact: 'website'
        };
        break;

      case FormTypes.FREELANCER:
        formData.extra = {
          location: 'freelancers',
          method_of_contact: 'freelancers'
        };
        break;

      case FormTypes.TOP_COMPOUNDS:
        formData.extra = {
          location: location,
          method_of_contact: 'Top Compounds Form'
        };
        break;

      case FormTypes.SELL_PROPERTY:
        formData.extra = {
          method_of_contact: 'website',
          property_type: values?.property_type?.value,
          compound: values?.compound?.label
        };
        break;

      case FormTypes.NAWY_NOW:
        formData.extra = {
          method_of_contact: 'Nawy Now Form',
          property_type: values?.property_type?.label,
          compound: values?.compound?.label,
          location: values?.area?.label
        };
        break;

      case FormTypes.NAWY_UNLOCKED:
        formData.extra = {
          method_of_contact: 'Nawy Unlocked Form',
          property_type: values?.property_type?.label,
          finishing_type: values?.finishing_type?.label,
          compound: values?.compound?.label,
          location: 'Nawy ROA'
        };
        break;

      case FormTypes.CITYSCAPE:
        formData.extra = {
          location: 'cityscape',
          method_of_contact: 'website'
        };
        break;
      case FormTypes.ELITE_COMPOUNDS:
        formData.extra = {
          location: location,
          method_of_contact: 'Elite Form'
        };
        break;
      case FormTypes.PRIME_COMPOUNDS:
        formData.extra = {
          location: location,
          method_of_contact: 'Generic Form'
        };
        break;
      case FormTypes.PARTNERS:
        formData.extra = {
          location: location,
          method_of_contact: 'Nawy Partners form'
        };
        break;
      default:
    }
    if (values.seller) {
      formData.extra = {
        property_type: values?.property_type?.value,
        method_of_contact: 'website',
        compound: values?.compound?.label
      };
    }
    if (landingPageId) {
      formData.extra = {
        ...formData.extra,
        landing_page_id: landingPageId
      };
    }
    formData.extra = {
      ...formData.extra,
      nawy_organization_id: values?.compound?.nawy_organization_id
    };
  };

  const getRequest = () => {
    if (location) {
      let { areaId, developerId, compoundId, propertyId }: any = location;

      if (compoundId) {
        return {
          compound_id: compoundId
        };
      }
      if (propertyId) {
        return {
          property_id: propertyId
        };
      }
      if (areaId) {
        return {
          area_id: areaId
        };
      }
      if (developerId) {
        return {
          developer_id: developerId
        };
      }
    } else if (formik.values.compound) {
      return {
        compound_id: formik.values.compound.value
      };
    }
    return {
      url: asPath
    };
  };

  const validate = (values: FormValues) => {
    const errors: any = {};
    if (!values.contact_name || !/\S/.test(values.contact_name)) {
      errors.contact_name = t('name_is_required');
    }
    if (!customerNumber.value)
      errors.contact_number = t('phone_number_is_required');
    else if (!customerNumber.valid)
      errors.contact_number = t('enter_valid_phone_number');

    if (!values.compound?.value && formSource == FormTypes.ORGANIC_FORM)
      errors.compound = t('location_is_required');

    if (
      !values.area?.value &&
      (formSource == FormTypes.SELL_PROPERTY || values.seller)
    )
      errors.area = t('location_is_required');
    if (
      !values.compound?.value &&
      (formSource == FormTypes.SELL_PROPERTY ||
        formSource == FormTypes.NAWY_UNLOCKED ||
        values.seller)
    )
      errors.compound = t('compound_is_required');

    if (
      !values.property_type?.value &&
      (formSource == FormTypes.SELL_PROPERTY ||
        formSource == FormTypes.NAWY_UNLOCKED ||
        values.seller)
    )
      errors.property_type = t('property_type_is_required');

    if (!values.contact_email && formSource == FormTypes.FREELANCER)
      errors.contact_email = t('email_is_required');

    if (!values.contact_notes && formSource == FormTypes.PARTNERS)
      errors.contact_notes = t('company_name_is_required');

    if (values.commercial_record == null && formSource == FormTypes.PARTNERS)
      errors.commercial_record = t('select_an_answer');

    if (
      values.working_in_realestate == null &&
      formSource == FormTypes.PARTNERS
    )
      errors.working_in_realestate = t('select_an_answer');

    return errors;
  };

  const handleResetForm = (preservedValues: {}) => {
    // reset all form values except 'compound'
    formik.resetForm({
      values: {
        ...initialFormValues, // reset other fields to their initial values
        ...preservedValues
      }
    });
    setCustomerNumber({ valid: true, value: '' });
  };

  const formik = useFormik({
    initialValues: initialFormValues,
    validate,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        let formData = {
          contact: {
            ...values
          },
          client_id: clientId,
          sbjs: {
            ...sbjs.get,
            current: {
              ...sbjs.get.current,
              cmp: query.utm_campaign || sbjs.get.current.cmp,
              src: query.utm_source || sbjs.get.current.src,
              typ:
                query.utm_campaign || query.utm_source
                  ? 'utm'
                  : sbjs.get.current.typ
            }
          },
          request: { ...getRequest(), url: asPath },
          location_id: new Date().getTime() - locationId,
          seller: formSource === FormTypes.SELL_PROPERTY || values.seller,
          financing: formSource === FormTypes.NAWY_NOW
        };
        logEvent.fbqContact(formData.contact);
        getFormExtraData(formData, values);
        if (formSource === FormTypes.SELL_PROPERTY) {
          const res = await FormsApis.submitContactForm(formData);
          if (res.status == 200) {
            sessionStorage.setItem('lead_id', res.data.lead_id);
          }
        } else {
          await FormsApis.submitContactFormNew(formData);
        }

        onFormSubmit();
        handleResetForm({
          ...(formSource !== FormTypes.SELL_PROPERTY && {
            compound: formik.values.compound
          }),
          ...(formSource === FormTypes.SELL_PROPERTY && {
            property_type: formik.values.property_type
          }),
          ...(formSource !== FormTypes.SELL_PROPERTY &&
            formSource !== FormTypes.NAWY_NOW && {
              compound: formik.values.compound
            }),
          ...(formSource == FormTypes.SELL_PROPERTY && {
            property_type: {}
          }),
          ...(formSource == FormTypes.NAWY_UNLOCKED && {
            compound: {}
          })
        });
      } catch (e) {
        setSubmitting(false);
      }
    }
  });

  return {
    formik,
    onPhoneNumberChange,
    customerNumber,
    handleResetForm
  };
};
