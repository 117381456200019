import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import PhoneInput, { Country } from 'react-phone-number-input';
import { countryList } from '../../constants';
import en from 'react-phone-number-input/locale/en.json';
import ar from 'react-phone-number-input/locale/ar.json';
import { isValidPhoneNumber } from 'libphonenumber-js/mobile';
import { Dispatch, SetStateAction } from 'react';
import DaysSlots from './DaysSlots';
import MeetingIcon from '../../../../new-components/svg-icons/MeetingIcon';

interface FormProps {
  formik: any;
  customerNumber: any;
  onPhoneNumberChange: (valid: any, value: any) => void;
  meetingLocation: string;
  setMeetingLocation: Dispatch<SetStateAction<string>>;
  meetingDay: string;
  setMeetingDay: Dispatch<SetStateAction<string>>;
  meetingTime: string;
  setMeetingTime: Dispatch<SetStateAction<string>>;
}

const RequestMeetingPresentation = ({
  formik,
  customerNumber,
  onPhoneNumberChange,
  meetingLocation,
  setMeetingLocation,
  meetingDay,
  setMeetingDay,
  meetingTime,
  setMeetingTime
}: FormProps) => {
  const { t } = useTranslation('contact');
  const { locale } = useRouter();

  return (
    <Container>
      <div className="form-header">
        <div className="meeting-icon-container">
          <MeetingIcon />
        </div>
        <h2 className="headline-2">{t('request_meeting')}</h2>
        <h3 className="text-2">{t('request_meeting_subtitle')}</h3>

        <div className="meeting-togglers">
          <button
            className={meetingLocation == 'zoom' ? 'active' : ''}
            onClick={() => setMeetingLocation('zoom')}
          >
            {t('zoom')}
          </button>
          <button
            className={meetingLocation == 'zoom' ? '' : 'active'}
            onClick={() => setMeetingLocation('new cairo')}
          >
            {t('physical_meeting')}
          </button>
        </div>
      </div>

      <form onSubmit={formik.handleSubmit} className="form">
        <div className="form-row">
          <h3 className="headline-2 headline">{t('complete_the_form')}</h3>
          <div className="form-inputs">
            <div className="form-input">
              <input
                type="text"
                placeholder={t('your_name')}
                id="contact_name"
                className="input-field"
                name="contact_name"
                value={formik.values.contact_name}
                onChange={formik.handleChange}
              />
              <div className="form-errors">
                {formik.errors.contact_name && formik.touched.contact_name
                  ? formik.errors.contact_name
                  : null}
              </div>
            </div>
            <div className="form-input">
              <div
                dir="ltr"
                style={{
                  justifyContent: locale == 'en' ? 'flex-start' : 'flex-end',
                  display: ' flex',
                  width: '100%',
                  position: 'relative'
                }}
              >
                <PhoneInput
                  value={customerNumber.value}
                  className="phone-input"
                  numberInputProps={{
                    id: 'contact_number',
                    name: 'contact_number',
                    type: 'text'
                  }}
                  countryCallingCodeEditable={true}
                  defaultCountry="EG"
                  labels={locale === 'en' ? en : ar}
                  countries={countryList.map(country => {
                    return country.code as Country;
                  })}
                  placeholder={t('phone_number')}
                  focusInputOnCountrySelection
                  style={{ direction: 'ltr' }}
                  onChange={value => {
                    if (value) {
                      onPhoneNumberChange(
                        isValidPhoneNumber((value as string) ?? ''),
                        value
                      );
                      formik.handleChange({
                        target: { name: 'contact_number', value: value }
                      });
                    }
                  }}
                />
              </div>
              <div className="form-errors">
                {formik.errors.contact_number && formik.touched.contact_number
                  ? formik.errors.contact_number
                  : null}
              </div>
            </div>
          </div>
        </div>
        <DaysSlots
          meetingDay={meetingDay}
          setMeetingDay={setMeetingDay}
          meetingTime={meetingTime}
          setMeetingTime={setMeetingTime}
        />
        {meetingLocation != 'zoom' && (
          <div className="form-row">
            <h3 className="headline-2 headline">{t('select_location')}</h3>
            <div className="locations">
              <button
                className={
                  meetingLocation == 'new cairo'
                    ? 'location selected'
                    : 'location'
                }
                onClick={() => setMeetingLocation('new cairo')}
              >
                {t('new_cairo')}{' '}
              </button>
              <button
                className={
                  meetingLocation == 'new cairo'
                    ? 'location'
                    : 'location selected'
                }
                onClick={() => setMeetingLocation('october')}
              >
                {t('6th_october')}{' '}
              </button>
            </div>
          </div>
        )}

        <button
          type="submit"
          className="submitBtn"
          disabled={formik.isSubmitting}
        >
          {formik.isSubmitting ? (
            <>
              <i className="fas fa-spinner fa-spin" />
              {t('submitting')}
            </>
          ) : (
            t('submit')
          )}
        </button>
      </form>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  background: ${props => props.theme.light.colors.white};
  padding: 30px 35px;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 600px;
  margin: auto;
  .form-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 20px;
    svg {
      width: 80px;
      height: auto;
      margin-bottom: 16px;
      path {
        fill: ${props => props.theme.light.colors.tertiary};
      }
    }
  }

  .meeting-togglers {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    button {
      width: 50%;
      border: none;
      border-bottom: 2px solid
        ${props => props.theme.light.borderColors.primary};
      background: none;
      outline: none;
      padding: 8px;
      text-align: center;
      font-family: materialRegular;

      &.active {
        border-bottom: 2px solid
          ${props => props.theme.light.colors.primaryBlue};
        color: ${props => props.theme.light.colors.primaryBlue};
        font-family: materialMedium;
      }
    }
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: materialRegular;

    .phone-input {
      width: 100%;
      display: flex;
      align-items: center;
      background-color: ${props => props.theme.light.backgroundColor};
      padding-inline-start: 16px;
      border-radius: ${props => props.theme.borderRadius};
      border: 1px solid #e6e6e6;

      input {
        width: 100%;
        background-color: ${props => props.theme.light.backgroundColor};
        padding: 16px;
        border: none !important;
        border: 1px solid transparent;
        border-radius: ${props => props.theme.borderRadius};
        border-start-start-radius: 0;
        border-end-start-radius: 0;
        border-inline-start: none;

        ::placeholder {
          font-family: materialRegular;
        }

        &:focus {
          outline: none !important;
        }
      }
    }

    .form-row {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 16px 0;
      border-bottom: 1px solid
        ${props => props.theme.light.borderColors.primary};
      .headline {
        margin-bottom: 8px;
      }

      .form-inputs {
        display: flex;
        flex-direction: row;
        gap: 8px;
      }

      .locations {
        display: flex;
        flex-direction: row;
        gap: 16px;

        .location {
          padding: 5px 16px;
          border: 1px solid ${props => props.theme.light.borderColors.primary};
          background: none;
          color: ${props => props.theme.light.colors.darkGrey};
          border-radius: ${props => props.theme.borderRadius};
          font-size: 14px;
          &.selected {
            border: 1px solid ${props => props.theme.light.colors.tertiary};
            color: ${props => props.theme.light.colors.tertiary};
          }
        }
      }
    }

    .form-input {
      width: 100%;
      position: relative;
      height: 55px;

      input {
        width: 100%;
        height: 45px;
        padding: 12px 16px;
        background-color: #ffffff;
        border: 1px solid #e6e6e6;
        border-radius: ${props => props.theme.borderRadius};
        outline: none;
      }
      .form-errors {
        font-size: 12px;
        color: #e70800;
        margin-top: 2px;
        margin-inline-start: 8px;
        position: absolute;
        bottom: -8px;
        font-family: materialRegular;
      }
    }

    button.submitBtn {
      width: 100%;
      max-width: 250px;
      height: 40px;
      font-size: 14px;
      margin-top: 16px;
      border: none;
      background-color: ${props => props.theme.light.colors.primaryBlue};
      color: white;
      border-radius: ${props => props.theme.borderRadius};
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 8px;

      &:hover {
        background-color: ${props => props.theme.light.hoverColors.primaryBlue};
      }
    }
  }

  @media screen and (max-width: ${props =>
      props.theme.breakPoints.tablet.max}) {
    padding: 20px 16px;

    .form-header {
      h2 {
        font-size: 16px;
      }
      h3 {
        font-size: 12px;
      }
    }
    .meeting-togglers {
      button {
        font-size: 14px;
      }
    }

    form {
      .form-row {
        .headline {
          font-size: 14px;
        }
        .form-inputs {
          flex-direction: column;
        }
      }
    }
  }

  @media (max-height: 750px) {
    .meeting-icon-container {
      display: none !important;
    }
  }
`;

export default RequestMeetingPresentation;
